<template>
  <header id="header" class="scroll-hide">
    <div class="header-wrapper">
      <div class="container">
        <div id="logo">
          <a href="/">
            <p>Hashmasks.</p>
          </a>
        </div>
        <nav>
          <div class="menu-button menu-open">
            <i class="open-icon ion-android-menu"></i
            ><i class="close-icon ion-android-close"></i>
          </div>
          <ul class="flexnav standard" data-breakpoint="800">
            <li>
              <router-link exact-active-class="active" to="/">Home</router-link>
            </li>
            <li
              @mouseover="hoverMenu = 'overview'"
              @mouseleave="hoverMenu = ''"
            >
              <router-link to="/overview" active-class="active">
                Overview
              </router-link>
              <ul :class="{ 'flexnav-show': hoverMenu === 'overview' }">
                <li>
                  <router-link to="/overview" exact-active-class="active">
                    Overview
                  </router-link>
                </li>
                <li>
                  <router-link to="/analytics" exact-active-class="active">
                    Analytics
                  </router-link>
                </li>
              </ul>
            </li>
            <li @mouseover="hoverMenu = 'gallery'" @mouseleave="hoverMenu = ''">
              <router-link to="/gallery" active-class="active">
                Gallery
              </router-link>
              <ul :class="{ 'flexnav-show': hoverMenu === 'gallery' }">
                <li>
                  <router-link to="/gallery" exact-active-class="active">
                    Gallery
                  </router-link>
                </li>
                <li>
                  <router-link to="/names" exact-active-class="active">
                    Names
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/nct" exact-active-class="active"
                >NCT Token</router-link
              >
            </li>
            <li @mouseover="hoverMenu = 'wallet'" @mouseleave="hoverMenu = ''">
              <router-link to="/wallet" active-class="active">
                My Wallet
              </router-link>
              <ul :class="{ 'flexnav-show': hoverMenu === 'wallet' }">
                <li>
                  <router-link to="/wallet" exact-active-class="active">
                    My Wallet
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/wallet-history"
                    exact-active-class="active"
                    :class="'not-pending-' + !pendingTx.length"
                  >
                    <span v-if="!pendingTx || !pendingTx.length">
                      Wallet History
                    </span>
                    <div class="header-pending-tx" v-else>
                      <button class="mint-button" disabled="disabled">
                        <div class="loading-circle"></div>
                        <span class="pending-text"
                          >{{ pendingTx.length }} Pending Tx</span
                        >
                      </button>
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              @mouseover="hoverMenu = 'doubledrop'"
              @mouseleave="hoverMenu = ''"
            >
              <router-link to="/doubledrop" active-class="active">
                Double Drop
              </router-link>
              <ul :class="{ 'flexnav-show': hoverMenu === 'doubledrop' }">
                <li>
                  <router-link to="/doubledrop" exact-active-class="active">
                    Claim
                  </router-link>
                </li>
                <li>
                  <router-link to="/graveyard" exact-active-class="active"
                    >Graveyard</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/elementals-rarity"
                    exact-active-class="active"
                    >Elementals Rarity</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/doubledrop-terms"
                    exact-active-class="active"
                    >Terms</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { ethers } from 'ethers';

export default {
  name: 'Header',
  data() {
    return {
      timer: '',
      hoverMenu: '',
      windowProvider: null,
    };
  },
  async mounted() {
    // Begin: Header Nav bar JS
    const open = document.querySelector('.open-icon');
    const close = document.querySelector('.close-icon');
    const nav = document.querySelector('.flexnav');
    open.addEventListener('click', function() {
      nav.style.maxHeight = 'none';
      open.style.display = 'none';
      close.style.display = 'block';
    });
    close.addEventListener('click', function() {
      nav.style.maxHeight = '0';
      open.style.display = 'block';
      close.style.display = 'none';
    });
    // End: Header Nav bar JS

    if (window.ethereum) {
      this.windowProvider = new ethers.providers.Web3Provider(window.ethereum);
      this.checkWalletAndPendingTransactions();
      this.timer = setInterval(this.checkWalletAndPendingTransactions, 1000);
    }
  },
  computed: {
    pendingTx() {
      return this.$store.state.pendingTx;
    },
  },
  methods: {
    async checkWalletAndPendingTransactions() {
      try {
        const signer = this.windowProvider.getSigner();
        const address = await signer.getAddress();
        this.$store.commit('setDefaultAccount', address);
        if (!this.$store.state.isWalletConnected) {
          this.$store.commit('setWalletWeb3');
        }
        for (const txDetails of this.$store.state.pendingTx) {
          const receipt = await this.windowProvider.getTransactionReceipt(
            txDetails.txHash,
          );
          if (receipt) {
            this.$store.commit('completeTx', {
              txDetails,
              status: receipt.status,
            });
          }
        }
      } catch (error) {
        return;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
